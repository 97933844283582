import {userAPI} from "../../domains/general/api/api"

/* Actions types */
const SET_AUTH = "AUTH/SET"
const SET_LOGOUT = "AUTH/OUT"
const SET_INFO = "INFO/SET"

/* Initial state */
let initialState = {
    isAuth: false,
    auth: {
        id: null,
        token: null
    },
    info: {},
    access: {}
}

/* State */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH: {
            return {
                ...state,
                isAuth: action.isAuth
            }
        }
        case SET_LOGOUT: {
            return {
                ...state,
                ...initialState
            }
        }
        case SET_INFO: {
            return {
                ...state,
                info: action.info
            }
        }
        default: return state
    }
}

/* Actions */
export const setAuthAC = (data) => {
    return {
        type: SET_AUTH,
        isAuth: data
    }
}
export const setLogoutAC = () => {
    return {
        type: SET_LOGOUT
    }
}
export const setMyInfoAC = (data) => {
    return {
        type: SET_INFO,
        info: data
    }
}


/* Thunks */
export const letAuthTC = (id=null, token=null) => {
    return (dispatch) => {
        userAPI.auth.me(id, token)
            .then(data => {
                dispatch(setAuthAC(data))
            })
    }
}
export const letUserWebLoginTC = (user, callback = null) => {
    return (dispatch) => {
        userAPI.auth.login(user)
            .then(data => {
                if (data.resultCode === 0) {
                    dispatch(letAuthTC(data.data.id, data.data.token))
                    dispatch(getMyInfoTC(data.data.id, data.data.token))
                } else if (data.resultCode === 1) {
                    callback(data.data)
                } else {}
            })
    }
}
export const letUserWebSignupTC = (user, callback = null) => {
    return (dispatch) => {
        userAPI.auth.signup(user)
            .then(data => {
                if (data.resultCode === 0) {
                    dispatch(letAuthTC(data.data.id, data.data.token))
                    dispatch(getMyInfoTC(data.data.id, data.data.token))
                } else if (data.resultCode === 1) {
                    callback(data.data)
                } else {}
            })
    }
}
export const letUserVkLoginTC = (code, callback = null) => {
    return (dispatch) => {
        userAPI.auth.vk.login(code)
            .then(data => {
                if (data.resultCode === 0) {
                    dispatch(letAuthTC(data.data.id, data.data.token))
                    dispatch(getMyInfoTC(data.data.id, data.data.token))
                }
                callback(data)
            })
    }
}
export const letUserVKSignupTC = (user, callback = null) => {
    return (dispatch) => {
        userAPI.auth.vk.signup(user)
            .then(data => {
                if (data.resultCode === 0) {
                    dispatch(letAuthTC(data.data.id, data.data.token))
                    dispatch(getMyInfoTC(data.data.id, data.data.token))
                } else if (data.resultCode === 1) {
                    callback(data.data)
                } else {}
            })
    }
}
export const letUserLogoutTC = () => {
    return (dispatch) => {
        userAPI.auth.logout()
        dispatch(setLogoutAC())
    }
}
export const getMyInfoTC = () => {
    return (dispatch) => {
        userAPI.info.getMe()
            .then(data => {
                dispatch(setMyInfoAC(data))
            })
    }
}

export default reducer