import {generalAPI} from "../../domains/general/api/api"

/* Actions types */
const SET_CURRENT_NEWS = "NEWS/SET_CURRENT"
const SET_LAST_NEWS = "NEWS/SET_LAST"
const SET_PROJECTS = "PROJECTS/SET_ALL"
const SET_SYSTEM_STYLE_BODY = "SYSTEM/STYLE/BODY"

/* Initial state */
let initialState = {
    news: {
        current: {
            news: [],
            count: 0
        },
        last: []
    },
    pages: {
        auth: true,
        news: true
    },
    projects: [
        // {
        //     "полное_название": "Фронт",
        //     "название": "front",
        //     name: "Сообщество «Фронт»",
        //     subdomen: null,
        //     logo: "https://xn----otbqdjelm.xn--p1ai/style/img/gerb_light.png",
        //     backgroundImg: null,
        //     guilds: []
        // }
    ],
    system: {
        style: {
            body: {
                defaultStyle: ["style-light", "day"],
                available: {
                    style: ["Light", "Plate", "Void"], // light/plate/void
                    colorScheme: ["Day", "Night"], // day/night
                    isColorScheme: [true, false]
                },
                currentStyle: {
                    style: 'light',
                    colorScheme: null,
                    isColorScheme: true,
                    time: {
                        dayStart: 8,
                        dayFinish: 20,
                        everyMinutes: 5
                    }
                }
            },
            menu: {
                projects: true,
                profile: true
            }
        }
    }
}

/* State */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_NEWS: {
            return {
                ...state,
                news: {
                    ...state.news,
                    current: {
                        ...state.news.current,
                        news: action.news.data,
                        count: action.news.total
                    }
                }
            }
        }
        case SET_LAST_NEWS: {
            return {
                ...state,
                news: {
                    ...state.news,
                    last: action.news
                }
            }
        }
        case SET_PROJECTS: {
            return {
                ...state,
                projects: action.projects
            }
        }
        case SET_SYSTEM_STYLE_BODY: {
            return {
                ...state,
                system: {
                    ...state.system,
                    style: {
                        ...state.system.style,
                        body: {
                            ...state.system.style.body,
                            currentStyle: {
                                ...state.system.style.body.currentStyle,
                                style: action.style.style || state.system.style.body.currentStyle.style,
                                colorScheme: action.style.colorScheme || state.system.style.body.currentStyle.colorScheme,
                                isColorScheme: action.style.isColorScheme || state.system.style.body.currentStyle.isColorScheme
                            }
                        }
                    }
                }
            }
        }
        default: return state
    }
}

/* Actions */
export const setCurrentNewsAC = (data) => {
    return {
        type: SET_CURRENT_NEWS,
        news: data
    }
}
export const setLastNewsAC = (data) => {
    return {
        type: SET_LAST_NEWS,
        news: data.data
    }
}
export const setProjectsAC = (data) => {
    return {
        type: SET_PROJECTS,
        projects: data
    }
}
export const setSystemBodyStyleAC = (data) => {
    return {
        type: SET_SYSTEM_STYLE_BODY,
        style: data
    }
}


/* Thunks */
export const getCurrentNewsTC = (params=null) => {
    return (dispatch) => {
        generalAPI.news.get(params)
            .then(data => {
            dispatch(setCurrentNewsAC(data))
        })
    }
}
export const getLastNewsTC = (params=null) => {
    return (dispatch) => {
        generalAPI.news.get(params).then(data => {
            dispatch(setLastNewsAC(data))
        })
    }
}
export const getProjectsTC = (id=null) => {
    return (dispatch) => {
        generalAPI.projects.get(id).then(data => {
            dispatch(setProjectsAC(data))
        })
    }
}
export const setSystemBodyStyleTC = (params={}) => {
    return (dispatch) => dispatch(setSystemBodyStyleAC(params))
}

export default reducer