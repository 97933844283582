import React from "react"

const Preloader = (props) => {
    return (
        <div style={{height:'100%', width:'100%', display:"flex", flexFlow:'column nowrap', justifyContent:'center', alignItems:'center'}}>
            <img src="https://cdn.dribbble.com/users/682556/screenshots/2177533/gooey_effect-spinner-loader.gif"
            style={{borderRadius:"var(--size-great)", display:'block', maxHeight:"-webkit-fill-available", maxWidth:"-webkit-fill-available"}}/>
        </div>
    )
}

export default Preloader