import axios from "axios";
import config from "./config";

export const instance = axios.create({
    baseURL: `${config.api.getUri()}/api/front/`,
    withCredentials: true,
    headers: {
        ...axios.defaults.headers,
        common: {
            ...axios.defaults.headers.common,
            'Authorization': JSON.stringify({
                key: config.app.key,
                client: config.app.client
            })
        }
    }
});

const ResponseErrors = (data) => {
    if (data) {
        if (data.messages) {
            data.messages.forEach(m => console.log(m))
        }
        if (data.errors) {
            data.errors.forEach(e => console.warn(e))
        }
    }
}
const generateKey = (length = 20) => {
    const chars = 'abdefhiknrstyzABDEFGHKNQRSTYZ23456789';
    const numChars = chars.length
    let string = ''
    for (let i = 0; i < length; i++) {
        const num = Math.floor(Math.random() * (numChars + 1))
        string += chars.substr(num, 1)
    }
    return string;
}

class Storage {
    constructor(path) {
        this.#config.path = path
    }

    #config = {
        path: 'user/auth'
    }

    get = () => {
        return window.localStorage.getItem(this.#config.path)
    }
    set = (value) => {
        window.localStorage.setItem(this.#config.path, value)
    }
    update = (value) => {
        this.set(value)
    }
    remove = () => {
        window.localStorage.removeItem(this.#config.path)
    }
}

export const userAPI = {
    auth: {
        me(id, token) {
            const link = 'user/login'
            let user = {
                id: id || null,
                token: token || null
            }
            const authorization = instance.defaults.headers.common['Authorization']!==undefined ? JSON.parse(instance.defaults.headers.common['Authorization']) : {}
            const storage = new Storage('user/auth')
            if (authorization.user===undefined && storage.get()) {
                user.id = storage.get().split(":")[0]
                user.token = storage.get().split(":")[1]
                if (user.id !== null && user.token !== null) {
                    instance.defaults.headers.common['Authorization'] = JSON.stringify({
                        ...authorization,
                        user: `USER ${user.id}:${user.token}:${generateKey(5)}`
                    })
                }
            } else if (authorization.user!==undefined) {
                if (authorization.user.split(" ")[0] === "USER") {
                    user.id = authorization.user.split(" ")[1].split(":")[0]
                    user.token = authorization.user.split(" ")[1].split(":")[1]
                }
            }

            if (user.id && user.token && !storage.get()) {
                storage.set(`${user.id}:${user.token}:${generateKey(user.token.length)}`)
            }

            return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    switch (response.status) {
                        case 200: {
                            if (response.data.data) {
                                storage.set(`${user.id}:${user.token}:${generateKey(user.token.length)}`)
                                return true
                            } else {
                                userAPI.auth.logout()
                                return false
                            }
                        }
                        case 500: {
                            return false
                        }
                        default:
                            return false
                    }
                })
        },
        login(user) {
            const link = 'user/login'
            const storage = new Storage('user/auth')
            return instance.post(link, user)
                .then(response => {
                    ResponseErrors(response.data)
                    if (response.status === 200) {
                        if (response.data.resultCode === 0) {
                            const authorization = instance.defaults.headers.common['Authorization']!==undefined ? JSON.parse(instance.defaults.headers.common['Authorization']) : {}
                            instance.defaults.headers.common['Authorization'] = JSON.stringify({
                                ...authorization,
                                user: `USER ${response.data.data.id}:${response.data.data.token}:${generateKey(response.data.data.token.length)}`
                            })
                            storage.set(`${response.data.data.id}:${response.data.data.token}`)
                        }
                        return response.data
                    }
                })
        },
        signup(user) {
            const link = 'user/signup'
            const storage = new Storage('user/auth')
            return instance.post(link, user)
                .then(response => {
                    ResponseErrors(response.data)
                    if (response.status === 200) {
                        if (response.data.resultCode === 0) {
                            const authorization = instance.defaults.headers.common['Authorization']!==undefined ? JSON.parse(instance.defaults.headers.common['Authorization']) : {}
                            instance.defaults.headers.common['Authorization'] = JSON.stringify({
                                ...authorization,
                                user: `USER ${response.data.data.id}:${response.data.data.token}:${generateKey(5)}`
                            })
                            storage.set(`${response.data.data.id}:${response.data.data.token}`)
                        }
                        return response.data
                    }
                })
        },
        logout() {
            let authorization = instance.defaults.headers.common['Authorization']!==undefined ? JSON.parse(instance.defaults.headers.common['Authorization']) : {}
            delete authorization.user
            const storage = new Storage('user/auth')
            instance.defaults.headers.common['Authorization'] = JSON.stringify(authorization)
            storage.remove()
        },
        vk: {
            login (code) {
                const link = `user/vk/login`
                return instance.post(link,{code:code})
                    .then(response => {
                        ResponseErrors(response.data)
                        if (response.status===200) {
                            return response.data
                        }
                    })
            },
            signup (user) {
                const link = 'user/vk/signup'
                const storage = new Storage('user/auth')
                return instance.post(link, user)
                    .then(response => {
                        ResponseErrors(response.data)
                        if (response.status === 200) {
                            if (response.data.resultCode === 0) {
                                const authorization = instance.defaults.headers.common['Authorization']!==undefined ? JSON.parse(instance.defaults.headers.common['Authorization']) : {}
                                instance.defaults.headers.common['Authorization'] = JSON.stringify({
                                    ...authorization,
                                    user: `USER ${response.data.data.id}:${response.data.data.token}:${generateKey(5)}`
                                })
                                storage.set(`${response.data.data.id}:${response.data.data.token}`)
                            }
                            return response.data
                        }
                    })
            }
        }
    },
    info: {
        getMe(id, token) {
            const link = 'user/info'
            const storage = new Storage('user/auth')
            let user = {
                id: id || null,
                token: token || null
            }
            const authorization = instance.defaults.headers.common['Authorization']!==undefined ? JSON.parse(instance.defaults.headers.common['Authorization']) : {}
            if (authorization.user===undefined && storage.get()) {
                user.id = storage.get().split(":")[0]
                user.token = storage.get().split(":")[1]
                if (user.id !== null && user.token !== null) {
                    instance.defaults.headers.common['Authorization'] = JSON.stringify({
                        ...authorization,
                        user: `USER ${user.id}:${user.token}:${generateKey(5)}`
                    })
                }
            } else if (authorization.user!==undefined) {
                if (authorization.user.split(" ")[0] === "USER") {
                    user.id = authorization.user.split(" ")[1].split(":")[0]
                    user.token = authorization.user.split(" ")[1].split(":")[1]
                }
            }
            return instance.get(link)
                .then(response => {
                    ResponseErrors(response.data)
                    if (response.status===200 && response.data.resultCode===0) {
                        return response.data.data
                    }
                })
        }
    }
}
export const generalAPI = {
    news: {
        get(request) {
            const config = {
                project: null,
                limit: null,
                page: null,
                ...request
            }
            let responseParams = []
            if (config.project !== null) {
                responseParams.push(`project=${config.project}`)
            }
            if (config.limit !== null) {
                responseParams.push(`limit=${config.limit}`)
            }
            if (config.page !== null) {
                responseParams.push(`page=${config.page}`)
            }
            responseParams = responseParams.length > 0 ? `?${responseParams.join("&")}` : ""
            const link = 'news' + responseParams
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status === 200) {
                    return response.data
                }
            })
        }
    },
    projects: {
        get(id = null) {
            const link = (id === null) ? 'projects' : `projects/${id}`
            return instance.get(link).then(response => {
                    if (response.status === 200 && response.data!==null) {
                        let array = response.data.data
                        array.forEach((v,i,a) => {
                            if (v['название']==='front') {
                                const el = i+1
                                a.unshift(v)
                                a.splice(el,1)
                            }
                        })
                        return array
                    } else {
                        ResponseErrors(response.data)
                        return []
                    }
                }
            )
        }
    },
    users: {
        get(id = null) {
            // pagination?
            const link = (id === null) ? 'users' : `users/${id}`
            return instance.get(link).then(response => {
                if (response.status === 200 && response.data!==null) {
                    return response.data.data
                } else {
                    ResponseErrors(response.data)
                }
            })
        }
    }
}