import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";

import reducerUser from "./user/reducer"
import reducerGeneral from "./general/reducer"
import reducerAllodsOnline from "./allods_online/reducer"

let reducers = combineReducers({
    user: reducerUser,
    general: reducerGeneral,
    allodsOnline: reducerAllodsOnline
})

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

window.store = store;

export default store;