import {projectAPI} from "../../domains/allods_online/api/api"

/* Actions types */
const SET_USER_AVATARS = "USER/AVATARS/SET"
const SET_USER_RANK = "USER/RANK/SET"
const PUSH_USER_RULES = "USER/RULES/PUSH"
const SET_GAME_ACHIEVEMENTS = "GAME/ACHIEVEMENTS/SET"
const SET_GAME_CLASSES = "GAME/CLASSES/SET"
const SET_GAME_RANKS = "GAME/RANKS/SET"
const SET_GAME_STATS = "GAME/STATS/SET"
const SET_ADDONS = "ADDONS/SET"
const SET_ADDON = "ADDONS/SET_CURRENT"
const PUSH_ADDONS = "ADDONS/PUSH"
const PUSH_ADDONS_LOADS = "ADDONS/LOADS/PUSH"
const UNPUSH_ADDONS_LOADS = "ADDONS/LOADS/UNPUSH"
const UPDATE_ADDONS_LAST = "ADDONS/LAST/UPDATE"
const SET_ADDONS_STUDIO_AUTHOR = "ADDONS/STUDIO/AUTHOR/SET"
const SET_ADDONS_STUDIO_ADDONS = "ADDONS/STUDIO/ADDONS/SET"
const SET_BUILDS = "BUILDS/SET"
const SET_BUILD = "BUILDS/SET_CURRENT"
const PUSH_BUILDS = "BUILDS/PUSH"
const PUSH_BUILDS_LOADS = "BUILDS/LOADS/PUSH"
const UNPUSH_BUILDS_LOADS = "BUILDS/LOADS/UNPUSH"
const UPDATE_BUILDS_LAST = "BUILDS/LAST/UPDATE"
const UPDATE_BUILDS_WAITING = "BUILDS/WAITING/UPDATE"
const DELETE_BUILD = "BUILDS/DELETE_CURRENT"
const UPDATE_MAPS_WAITING = "MAPS/WAITING/UPDATE"
const SET_MAPS = "MAPS/SET"
const SET_MAP = "MAPS/SET_CURRENT"
const SET_POSTS_TAGS = "POSTS/TAGS/SET"
const SET_POSTS = "POSTS/SET"
const SET_POST = "POSTS/SET_CURRENT"
const PUSH_POSTS = "POSTS/PUSH"
const UPDATE_POST_LAST = "POSTS/LAST/UPDATE"
const UPDATE_POSTS_WAITING = "POSTS/WAITING/UPDATE"
const DELETE_POST = "POSTS/DELETE_CURRENT"
const SET_RULES = "RULES/SET"
const SET_GUILDS = "GUILDS/SET"

/* Initial state */
let initialState = {
    config: {
        name: 'allods_online'
    },
    user: {
        avatars: null,
        rank: null,
        rules: {}
    },
    game: {
        achievements: [],
        classes: [
            // {
            //     id: "ClassID",
            //     name: "ClassName",
            //     logo: "ClassLogo",
            //     aspects: []
            // }
        ],
        classesAspects: {
            'Защиты': {
                link: 'https://allods.mail.ru/images/news/Aspect_Tank.png',
                name: {
                    ru: 'Аспект защиты'
                },
                text: 'Персонаж берет на себя роль крепкого защитника, отвлекающего на себя внимание врагов.'
            },
            'Исцеления': {
                link: 'https://allods.mail.ru/images/news/Aspect_Heal.png',
                name: {
                    ru: 'Аспект исцеления'
                },
                text: 'Персонаж берет на себя роль боевого целителя, заботящегося о раненых союзниках.'
            },
            'Поддержки': {
                link: 'https://allods.mail.ru/images/news/Aspect_Buff.png',
                name: {
                    ru: 'Аспект поддержки'
                },
                text: 'Персонаж берет на себя роль поддержки, усиляя союзников и/или ослабляя врагов в решающие моменты боя.'
            },
            'Нападения': {
                link: 'https://allods.mail.ru/images/news/Aspect_Damage.png',
                name: {
                    ru: 'Аспект нападения'
                },
                text: 'Персонаж берет на себя роль могучего бойца, безжалостно уничтожающего своих противников.'
            },
            'Подавления': {
                link: 'https://allods.mail.ru/images/news/Aspect_Control.png',
                name: {
                    ru: 'Аспект подавления'
                },
                text: 'Персонаж берет на себя роль искусного стратега, выводящего противников из строя. Контролирующие врагов умения и заклинания подготавливаются мгновенно.'
            }
        },
        ranks: [],
        stats: null
    },
    guilds: [
        // {
        //     name: null,
        //     key: null,
        //     ordinance: null,
        //     greeting: null
        // }
    ],
    addons: {
        view: {
            all: [],
            totalCount: 0,
            current: null,
            tags: [],
            last: {
                page: 1
            },
        },
        studio: {
            author: {
                verified: false,
                access: false
            },
            addons: {
                all: [],
                current: null,
                totalCount: 0
            }
        },
        loads: []
    },
    builds: {
        all: [],
        classesCount: {},
        totalCount: 0,
        current: null,
        last: {
            className: null,
            page: 1
        },
        loads: [],
        isWaiting: true
    },
    maps: {
        all: [],
        current: null,
        isWaiting: true
    },
    posts: {
        all: [],
        totalCount: 0,
        current: null,
        tags: [],
        last: {
            page: 1
        },
        isWaiting: true
    },
    rules: []
}

/* State */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_AVATARS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    avatars: action.avatars
                }
            }
        }
        case SET_USER_RANK: {
            return {
                ...state,
                user: {
                    ...state.user,
                    rank: action.rank
                }
            }
        }
        case PUSH_USER_RULES: {
            return {
                ...state,
                user: {
                    ...state.user,
                    rules: {
                        ...state.user.rules,
                        ...action.rules
                    }
                }
            }
        }
        case SET_GAME_ACHIEVEMENTS: {
            return {
                ...state,
                game: {
                    ...state.game,
                    achievements: action.achievements
                }
            }
        }
        case SET_GAME_CLASSES: {
            return {
                ...state,
                game: {
                    ...state.game,
                    classes: action.classes
                }
            }
        }
        case SET_GAME_RANKS: {
            return {
                ...state,
                game: {
                    ...state.game,
                    ranks: action.ranks
                }
            }
        }
        case SET_GAME_STATS: {
            return {
                ...state,
                game: {
                    ...state.game,
                    stats: action.stats
                }
            }
        }
        case SET_ADDONS: {
            return {
                ...state,
                addons: {
                    ...state.addons,
                    view: {
                        ...state.addons.view,
                        all: [action.addons.addons],
                        totalCount: action.addons.totalCount
                    }
                }
            }
        }
        case SET_ADDON: {
            return {
                ...state,
                addons: {
                    ...state.addons,
                    view: {
                        ...state.addons.view,
                        current: action.addon
                    }
                }
            }
        }
        case PUSH_ADDONS: {
            let obj = {
                ...state,
                addons: {
                    ...state.addons,
                    view: {
                        ...state.addons.view,
                        all: [...state.addons.view.all],
                        totalCount: action.addons.totalCount
                    }
                }
            }
            obj.addons.view.all[action.page] = action.addons.addons
            return obj
        }
        case PUSH_ADDONS_LOADS: {
            return {
                ...state,
                addons: {
                    ...state.addons,
                    loads: [...state.addons.loads, action.name]
                }
            }
        }
        case UNPUSH_ADDONS_LOADS: {
            let obj = {
                ...state,
                addons: {
                    ...state.addons,
                    loads: [...state.addons.loads].filter(name => name !== action.name)
                }
            }
            return obj
        }
        case UPDATE_ADDONS_LAST: {
            return {
                ...state,
                addons: {
                    ...state.addons,
                    view: {
                        ...state.addons.view,
                        last: {
                            ...state.addons.last,
                            ...action.last
                        }
                    }
                }
            }
        }
        case SET_ADDONS_STUDIO_AUTHOR: {
            return {
                ...state,
                addons: {
                    ...state.addons,
                    studio: {
                        ...state.addons.studio,
                        author: {
                            ...state.addons.studio.author,
                            ...action.author
                        }
                    }
                }
            }
        }
        case SET_ADDONS_STUDIO_ADDONS: {
            return {
                ...state,
                addons: {
                    ...state.addons,
                    studio: {
                        ...state.addons.studio,
                        addons: {
                            ...state.addons.studio.addons,
                            all: action.addons,
                            totalCount: action.addons.length
                        }
                    }
                }
            }
        }
        case SET_BUILDS: {
            return {
                ...state,
                builds: {
                    ...state.builds,
                    all: [action.builds.builds],
                    classesCount: {
                        ...state.builds.classesCount,
                        ...action.builds.classesCount
                    },
                    totalCount: action.builds.totalCount
                }
            }
        }
        case SET_BUILD: {
            return {
                ...state,
                builds: {
                    ...state.builds,
                    current: action.build
                }
            }
        }
        case PUSH_BUILDS: {
            let obj = {
                ...state,
                builds: {
                    ...state.builds,
                    all: [...state.builds.all],
                    classesCount: {
                        ...state.builds.classesCount,
                        ...action.builds.classesCount
                    },
                    totalCount: action.builds.totalCount
                }
            }
            obj.builds.all[action.page] = action.builds.builds
            return obj
        }
        case UPDATE_BUILDS_LAST: {
            return {
                ...state,
                builds: {
                    ...state.builds,
                    last: {
                        ...state.builds.last,
                        ...action.last
                    }
                }
            }
        }
        case UPDATE_BUILDS_WAITING: {
            return {
                ...state,
                builds: {
                    ...state.builds,
                    isWaiting: action.status
                }
            }
        }
        case DELETE_BUILD: {
            return {
                ...state,
                builds: {
                    ...state.builds,
                    current: initialState.builds.current
                }
            }
        }
        case UPDATE_MAPS_WAITING: {
            return {
                ...state,
                maps: {
                    ...state.maps,
                    isWaiting: action.status
                }
            }
        }
        case SET_MAPS: {
            return {
                ...state,
                maps: {
                    ...state.maps,
                    all: action.maps
                }
            }
        }
        case SET_MAP: {
            return {
                ...state,
                maps: {
                    ...state.maps,
                    current: action.map
                }
            }
        }
        case SET_POSTS_TAGS: {
            return {
                ...state,
                posts: {
                    ...state.posts,
                    tags: action.tags
                }
            }
        }
        case SET_POSTS: {
            return {
                ...state,
                posts: {
                    ...state.posts,
                    all: [action.posts.posts],
                    totalCount: action.posts.totalCount
                }
            }
        }
        case SET_POST: {
            return {
                ...state,
                posts: {
                    ...state.posts,
                    current: action.post
                }
            }
        }
        case PUSH_POSTS: {
            let obj = {
                ...state,
                posts: {
                    ...state.posts,
                    all: [...state.posts.all],
                    totalCount: action.posts.totalCount
                }
            }
            obj.posts.all[action.page] = action.posts.posts
            return obj
        }
        case UPDATE_POST_LAST: {
            return {
                ...state,
                posts: {
                    ...state.posts,
                    last: {
                        ...state.posts.last,
                        ...action.last
                    }
                }
            }
        }
        case UPDATE_POSTS_WAITING: {
            return {
                ...state,
                posts: {
                    ...state.posts,
                    isWaiting: action.status
                }
            }
        }
        case DELETE_POST: {
            return {
                ...state,
                posts: {
                    ...state.posts,
                    current: initialState.posts.current
                }
            }
        }
        case SET_RULES: {
            return {
                ...state,
                rules: action.rules
            }
        }
        case SET_GUILDS: {
            return {
                ...state,
                guilds: action.guilds
            }
        }
        default:
            return state
    }
}

/* Actions */
export const setUserAvatarsAC = (data) => {
    return {
        type: SET_USER_AVATARS,
        avatars: data
    }
}
export const setUserRankAC = (data) => {
    return {
        type: SET_USER_RANK,
        rank: data
    }
}
export const pushUserRulesAC = (data) => {
    return {
        type: PUSH_USER_RULES,
        rules: data
    }
}
export const setGameAchievementsAC = (data) => {
    return {
        type: SET_GAME_ACHIEVEMENTS,
        achievements: data
    }
}
export const setGameClassesAC = (data) => {
    return {
        type: SET_GAME_CLASSES,
        classes: data
    }
}
export const setGameRanksAC = (data) => {
    return {
        type: SET_GAME_RANKS,
        ranks: data
    }
}
export const setGameStatsAC = (data) => {
    return {
        type: SET_GAME_STATS,
        stats: data
    }
}
export const setAddonsAC = (data) => {
    return {
        type: SET_ADDONS,
        addons: data
    }
}
export const setCurrentAddonAC = (data) => {
    return {
        type: SET_ADDON,
        addon: data
    }
}
export const pushAddonsAC = (data, page) => {
    return {
        type: PUSH_ADDONS,
        addons: data,
        page: page - 1
    }
}
export const pushAddonsLoadsAC = (name) => {
    return {
        type: PUSH_ADDONS_LOADS,
        name: name
    }
}
export const unpushAddonsLoadsAC = (name) => {
    return {
        type: UNPUSH_ADDONS_LOADS,
        name: name
    }
}
export const updateAddonsLastAC = (data) => {
    return {
        type: UPDATE_ADDONS_LAST,
        last: data
    }
}
export const setAddonsStudioAuthorAC = (data) => {
    return {
        type: SET_ADDONS_STUDIO_AUTHOR,
        author: data
    }
}
export const setAddonsStudioAddonsAC = (data) => {
    return {
        type: SET_ADDONS_STUDIO_ADDONS,
        addons: data.addons
    }
}
export const setBuildsAC = (data) => {
    return {
        type: SET_BUILDS,
        builds: data
    }
}
export const setCurrentBuildAC = (data) => {
    return {
        type: SET_BUILD,
        build: data
    }
}
export const pushBuildsAC = (data, page) => {
    return {
        type: PUSH_BUILDS,
        builds: data,
        page: page - 1
    }
}
export const pushBuildsLoads = (name) => {
    return {
        type: PUSH_BUILDS_LOADS,
        name: name
    }
}
export const unpushBuildsLoads = (name) => {
    return {
        type: UNPUSH_BUILDS_LOADS,
        name: name
    }
}
export const updateBuildLastAC = (data) => {
    return {
        type: UPDATE_BUILDS_LAST,
        last: data
    }
}
export const updateBuildsWaitingAC = (data) => {
    return {
        type: UPDATE_BUILDS_WAITING,
        status: data
    }
}
export const deleteCurrentBuildAC = () => {
    return {
        type: DELETE_BUILD
    }
}
export const updateMapsWaitingAC = (data) => {
    return {
        type: UPDATE_MAPS_WAITING,
        status: data
    }
}
export const setMapsAC = (data) => {
    return {
        type: SET_MAPS,
        maps: data
    }
}
export const setCurrentMapAC = (data) => {
    return {
        type: SET_MAP,
        map: data
    }
}
export const setPostsTagsAC = (data) => {
    return {
        type: SET_POSTS_TAGS,
        tags: data
    }
}
export const setPostsAC = (data) => {
    return {
        type: SET_POSTS,
        posts: data
    }
}
export const setCurrentPostAC = (data) => {
    return {
        type: SET_POST,
        post: data
    }
}
export const pushPostsAC = (data, page) => {
    return {
        type: PUSH_POSTS,
        posts: data,
        page: page - 1
    }
}
export const updatePostLastAC = (data) => {
    return {
        type: UPDATE_POST_LAST,
        last: data
    }
}
export const updatePostsWaitingAC = (data) => {
    return {
        type: UPDATE_POSTS_WAITING,
        status: data
    }
}
export const deleteCurrentPostAC = () => {
    return {
        type: DELETE_POST
    }
}
export const setRulesAC = (data) => {
    return {
        type: SET_RULES,
        rules: data
    }
}
export const setGuildsAC = (data) => {
    return {
        type: SET_GUILDS,
        guilds: data
    }
}

/* Thunks */
export const getUserAvatarsTC = (id = null) => {
    return (dispatch) => {
        projectAPI.user.avatars.get(id).then(data => {
            dispatch(setUserAvatarsAC(data))
        })
    }
}
export const getUserRankTC = (id = null) => {
    return (dispatch) => {
        projectAPI.user.rank.get(id).then(data => {
            dispatch(getUserRulesTC())
            dispatch(setUserRankAC(data))
        })
    }
}
export const getUserRulesTC = (pageName = null) => {
    return (dispatch) => {
        projectAPI.user.rules.get(pageName).then(data => {
            dispatch(pushUserRulesAC(data))
        })
    }
}
export const getGameAchievementsTC = () => {
    return (dispatch) => {
        projectAPI.game.achievements.get().then(data => {
            dispatch(setGameAchievementsAC(data))
        })
    }
}
export const getGameClassesTC = () => {
    return (dispatch) => {
        projectAPI.game.classes.get().then(data => {
            dispatch(setGameClassesAC(data))
        })
    }
}
export const getGameRanksTC = () => {
    return (dispatch) => {
        projectAPI.game.ranks.get().then(data => {
            dispatch(setGameRanksAC(data))
        })
    }
}
export const getGameStatsTC = () => {
    return (dispatch) => {
        projectAPI.game.stats.get().then(data => {
            dispatch(setGameStatsAC(data))
        })
    }
}
export const getAddonsTC = (params) => {
    return (dispatch) => {
        const key = projectAPI.generateKey(8)
        dispatch(pushAddonsLoadsAC(`addons_${key}`))
        projectAPI.addons.view.get(params).then(data => {
            if (params !== undefined && params !== null) {
                if (params.page !== undefined && params.page > 1) {
                    dispatch(pushAddonsAC(data, params.page))
                } else {
                    dispatch(setAddonsAC(data))
                }
            } else {
                dispatch(setAddonsAC(data))
            }
            dispatch(unpushAddonsLoadsAC(`addons_${key}`))
        })
    }
}
export const getAddonTC = (id) => {
    return (dispatch) => {
        const key = projectAPI.generateKey(8)
        dispatch(pushAddonsLoadsAC(`addon_${key}`))
        projectAPI.addons.view.getCurrent(id).then(data => {
            dispatch(setCurrentAddonAC(data))
            dispatch(unpushAddonsLoadsAC(`addon_${key}`))
        })
    }
}
export const updateAddonRatingTC = (id, value) => {
    return (dispatch) => {
        projectAPI.addons.view.rating.update(id, value).then(data => {
            data && dispatch(getAddonTC(id))
        })
    }
}
export const updateAddonsLastTC = (params = null) => {
    return (dispatch) => {
        params !== null && dispatch(updateAddonsLastAC(params))
    }
}
export const getAddonsStudioAuthorTC = () => {
    return (dispatch) => {
        const key = projectAPI.generateKey(8)
        dispatch(pushAddonsLoadsAC(`studio_author_${key}`))
        projectAPI.addons.studio.author.get().then(data => {
            dispatch(unpushAddonsLoadsAC(`studio_author_${key}`))
            dispatch(setAddonsStudioAuthorAC(data))
        })
    }
}
export const createAddonsStudioAuthorTC = () => {
    return (dispatch) => {
        const key = projectAPI.generateKey(8)
        dispatch(pushAddonsLoadsAC(`studio_author_${key}`))
        projectAPI.addons.studio.author.create().then(() => {
            dispatch(unpushAddonsLoadsAC(`studio_author_${key}`))
            dispatch(getAddonsStudioAuthorTC())
        })
    }
}
export const getAddonsStudioAddonsTC = () => {
    return (dispatch) => {
        const key = projectAPI.generateKey(8)
        dispatch(pushAddonsLoadsAC(`studio_author_${key}`))
        projectAPI.addons.studio.addons.get().then(data => {
            dispatch(setAddonsStudioAddonsAC(data))
            dispatch(unpushAddonsLoadsAC(`studio_author_${key}`))
        })
    }
}
export const getBuildsTC = (className, params) => {
    return (dispatch) => {
        dispatch(updateBuildsWaitingAC(true))
        const key = projectAPI.generateKey(8)
        dispatch(pushBuildsLoads(`builds_${key}`))
        projectAPI.builds.get(className, params).then(data => {
            if (params !== undefined && params !== null) {
                if (params.page !== undefined && params.page > 1) {
                    dispatch(pushBuildsAC(data, params.page))
                } else {
                    dispatch(setBuildsAC(data))
                }
            } else {
                dispatch(setBuildsAC(data))
            }
            dispatch(unpushBuildsLoads(`builds_${key}`))
            dispatch(updateBuildsWaitingAC(false))
        })
    }
}
export const getBuildTC = (id) => {
    return (dispatch) => {
        dispatch(updateBuildsWaitingAC(true))
        const key = projectAPI.generateKey(8)
        dispatch(pushBuildsLoads(`build_${key}`))
        projectAPI.builds.getCurrent(id).then(data => {
            dispatch(setCurrentBuildAC(data))
            dispatch(unpushBuildsLoads(`build_${key}`))
            dispatch(updateBuildsWaitingAC(false))
        })
    }
}
export const createBuildTC = (build, callback = null) => {
    return () => {
        projectAPI.builds.create(build).then(data => {
            callback(data)
        })
    }
}
export const updateBuildTC = (build, callback = null) => {
    return () => {
        projectAPI.builds.update(build).then(data => {
            callback(data)
        })
    }
}
export const updateBuildLastTC = (params = null) => {
    return (dispatch) => {
        params !== null && dispatch(updateBuildLastAC(params))
    }
}
export const deleteBuildTC = (id, callback = null) => {
    return (dispatch) => {
        projectAPI.builds.delete(id).then(data => {
            if (data.resultCode === 0) {
                dispatch(deleteCurrentBuildAC())
            }
            callback(data)
        })
    }
}
export const getMapTC = (id = null) => {
    return (dispatch) => {
        dispatch(updateMapsWaitingAC(true))
        projectAPI.maps.getCurrent(id).then(data => {
            dispatch(setCurrentMapAC(data))
            dispatch(updateMapsWaitingAC(false))
        })
    }
}
export const getMapsTC = () => {
    return (dispatch) => {
        dispatch(updateMapsWaitingAC(true))
        projectAPI.maps.get().then(data => {
            dispatch(setMapsAC(data))
            dispatch(updateMapsWaitingAC(false))
        })
    }
}
export const getPostsTagsTC = () => {
    return (dispatch) => {
        projectAPI.posts.getTags().then(data => {
            dispatch(setPostsTagsAC(data))
        })
    }
}
export const getPostTC = (id) => {
    return (dispatch) => {
        dispatch(updatePostsWaitingAC(true))
        projectAPI.posts.getCurrent(id).then(data => {
            dispatch(setCurrentPostAC(data))
            dispatch(updatePostsWaitingAC(false))
        })
    }
}
export const getPostsTC = (params) => {
    return (dispatch) => {
        dispatch(updatePostsWaitingAC(true))
        projectAPI.posts.get(params).then(data => {
            if (params !== undefined && params !== null) {
                if (params.page !== undefined && params.page > 1) {
                    dispatch(pushPostsAC(data, params.page))
                } else {
                    dispatch(setPostsAC(data))
                }
            } else {
                dispatch(setPostsAC(data))
            }
            dispatch(updatePostsWaitingAC(false))
        })
    }
}
export const createPostTC = (post, callback = null) => {
    return () => {
        projectAPI.posts.create(post).then(data => {
            callback(data)
        })
    }
}
export const updatePostTC = (post, callback = null) => {
    return () => {
        projectAPI.posts.update(post).then(data => {
            callback(data)
        })
    }
}
export const updatePostLastTC = (params = null) => {
    return (dispatch) => {
        params !== null && dispatch(updatePostLastAC(params))
    }
}
export const deletePostTC = (id, callback = null) => {
    return (dispatch) => {
        projectAPI.posts.delete(id).then(data => {
            if (data.resultCode === 0) {
                dispatch(deleteCurrentPostAC())
            }
            callback(data)
        })
    }
}
export const getRulesTC = () => {
    return (dispatch) => {
        projectAPI.rules.get().then(data => {
            dispatch(getGameRanksTC())
            dispatch(setRulesAC(data))
        })
    }
}
export const updateRulesTC = (rules) => {
    return (dispatch) => {
        projectAPI.rules.update(rules).then(() => {
            dispatch(getUserRulesTC())
        })
    }
}
export const getGuildsTC = () => {
    return (dispatch) => {
        projectAPI.guilds.get().then(data => {
            dispatch(setGuildsAC(data))
        })
    }
}

export default reducer