import axios from "axios";
import config from "../../general/api/config";

const instance = axios.create({ // axios
    baseURL: `${config.api.getUri()}/api/allods_online/`,
    withCredentials: true,
    headers: {
        ...axios.defaults.headers,
        common: {
            ...axios.defaults.headers.common,
            'Authorization': JSON.stringify({
                key: config.app.key,
                client: config.app.client
            })
        }
    }
});
instance.defaults.headers.common = require('../../general/api/api').instance.defaults.headers.common

const ResponseErrors = (data) => {
    if (config.app.consoleErros) {
        if (data.messages) {
            data.messages.forEach(m => console.log(m))
        }
        if (data.errors) {
            data.errors.forEach(e => console.warn(e))
        }
    }
}
const generateKey = (length = 20) => {
    const chars = 'abdefhiknrstyzABDEFGHKNQRSTYZ23456789';
    const numChars = chars.length
    let string = ''
    for (let i = 0; i < length; i++) {
        const num = Math.floor(Math.random() * (numChars + 1))
        string += chars.substr(num, 1)
    }
    return string;
}

export const projectAPI = {
    generateKey: (length) => { return generateKey(length) },
    user: {
        avatars: {
            get(id=null) {
                const link = id ? `avatars/${id}` : `avatars`
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status===200 && response.data.resultCode===0) {
                        return response.data.data
                    }
                })
            }
        },
        rank: {
            get(id=null) {
                const link = id ? `rank/${id}` : `rank`
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status===200 && response.data.resultCode===0) {
                        return response.data.data
                    }
                })
            }
        },
        rules: {
            get(pageName = null) {
                const link = pageName ? `user/rules/${pageName}` : 'user/rules'
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status===200 && response.data.resultCode===0) {
                        return response.data.data
                    }
                })
            }
        }
    },
    game: {
        achievements: {
            get() {
                const link = 'game/achievements'
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status===200 && response.data.resultCode===0) {
                        return response.data.data
                    }
                })
            }
        },
        classes: {
            get() {
                const link = 'game/classes'
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status===200 && response.data.resultCode===0) {
                        return response.data.data
                    }
                })
            }
        },
        ranks: {
            get() {
                const link = 'game/ranks'
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status===200 && response.data.resultCode===0) {
                        return response.data.data
                    }
                })
            }
        },
        stats: {
            get() {
                const link = 'game/stats'
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status===200 && response.data.resultCode===0) {
                        return response.data.data
                    }
                })
            }
        }
    },
    addons: {
        view: {
            get(params = null) {
                let link = 'addons/view'
                if (params !== undefined || params !== null) {
                    let responseParams = []
                    responseParams = params.limit !== undefined && [...responseParams, `limit=${params.limit}`]
                    responseParams = params.page !== undefined && [...responseParams, `page=${params.page}`]
                    link = responseParams.length > 0 ? `${link}?${responseParams.join("&")}` : link
                }
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status === 200 && response.data.resultCode === 0) {
                        return response.data.data
                    }
                })
            },
            getCurrent(id, branch = null) {
                let link = `addons/view/${id}`
                link = branch ? `${link}/${branch}` : link
                return instance.get(link).then(response => {
                    ResponseErrors(response.data)
                    if (response.status === 200 && response.data.resultCode === 0) {
                        return response.data.data
                    }
                })
            },
            rating: {
                update: (id, value) => {
                    let link = `/addons/rating`
                    return instance.post(link, {id:id, value:value}).then(response => {
                        ResponseErrors(response.data)
                        if (response.status === 200 && response.data.resultCode === 0) {
                            return true
                        } else {
                            return false
                        }
                    })
                }
            }
        },
        studio: {
            addons: {
                get: () => {
                    let link = `addons/studio/addons`
                    return instance.get(link).then(response => {
                        ResponseErrors(response.data)
                        if (response.status===200 && response.data.resultCode===0) {
                            return response.data.data
                        }
                    })
                }
            },
            author: {
                get: () => {
                    let link = `addons/studio/author`
                    return instance.get(link).then(response => {
                        ResponseErrors(response.data)
                        if (response.status===200 && response.data.resultCode===0) {
                            return response.data.data
                        }
                    })
                },
                create: () => {
                    let link = `addons/studio/author`
                    return instance.post(link).then(response => {
                        ResponseErrors(response.data)
                        if (response.status===200 && response.data.resultCode===0) {
                            return response.data.data
                        }
                    })
                }
            }
        }
    },
    builds: {
        get(className=null, params=null) {
            let link = className===null ? 'builds' : `builds/${className}`
            if (params!==undefined) {
                let responseParams = []
                responseParams = params.limit !== undefined && [...responseParams, `limit=${params.limit}`]
                responseParams = params.page !== undefined && [...responseParams, `page=${params.page}`]
                link = responseParams.length>0 ? `${link}?${responseParams.join("&")}` : link
            }
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    return response.data.data
                }
            })
        },
        getCurrent(id) {
            const link = `build/${id}`
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    response.data.data['характеристики'] = JSON.parse(response.data.data['характеристики'])
                    return response.data.data
                }
            })
        },
        create(build) {
            const link = 'build'
            return instance.post(link, build).then(response => {
                ResponseErrors(response.data)
                return response.data
            })
        },
        update(build) {
            const link = `build/${build.id}`
            return instance.put(link, build).then(response => {
                ResponseErrors(response.data)
                return response.data
            })
        },
        delete(id) {
            const link = `build/${id}`
            return instance.delete(link).then(response => {
                ResponseErrors(response.data)
                return response.data
            })
        }
    },
    maps: {
        get() {
            const link = `maps`
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    return response.data.data
                }
            })
        },
        getCurrent(id) {
            const link = `maps/${id}`
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    return response.data.data
                }
            })
        }
    },
    posts: {
        getTags() {
            const link = `posts/tags`
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    return response.data.data
                }
            })
        },
        get(params) {
            let link = `posts`
            if (params!==undefined) {
                let responseParams = []
                responseParams = params.limit !== undefined && [...responseParams, `limit=${params.limit}`]
                responseParams = params.page !== undefined && [...responseParams, `page=${params.page}`]
                link = responseParams.length>0 ? `${link}?${responseParams.join("&")}` : link
            }
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    return response.data.data
                }
            })
        },
        getCurrent(id) {
            const link = `posts/${id}`
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    return response.data.data
                }
            })
        },
        create(post) {
            const link = 'posts'
            return instance.post(link, post).then(response => {
                ResponseErrors(response.data)
                return response.data
            })
        },
        update(post) {
            const link = `posts/${post.id}`
            return instance.put(link, post).then(response => {
                ResponseErrors(response.data)
                return response.data
            })
        },
        delete(id) {
            const link = `posts/${id}`
            return instance.delete(link).then(response => {
                ResponseErrors(response.data)
                return response.data
            })
        }
    },
    rules: {
        get() {
            const link = 'rules'
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    return response.data.data
                }
            })
        },
        update(rules) {
            const link = 'rules'
            return instance.put(link, rules).then(response => {
                ResponseErrors(response.data)
            })
        }
    },
    guilds: {
        get() {
            const link = 'guilds'
            return instance.get(link).then(response => {
                ResponseErrors(response.data)
                if (response.status===200 && response.data.resultCode===0) {
                    return response.data.data
                }
            })
        }
    }
}

export default projectAPI