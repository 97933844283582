/*
TODO: Промо страница
TODO: Создание модуля статистики и аналитики AO + аддон-парсер (по nodejs + APIkey) - в приложении
TODO: Создать модуль ao.Аддонов (с формой комментариев к аддону и авторам + поддержкой авторов удобными им способами)
TODO: Создать страницу пользователя включающую модуль "Пользователь" из каждого проекта
TODO: +- Связать с вебсокетом все узлы для моментального обновления
TODO: +- Перенос модуля карт
TODO: Зарефакторить карты, добавить возможность изменения блока через useRef
TODO: Перенос модуля тактик рейды+компаса
TODO: Создать форму достижений, званий и ачивок по всей системе - для персонализированного доступа к функциям страниц и модулей
TODO: оптимизация загрузок по UseEffect
    - ao.Билды
    - ao.Билды
TODO: Создать модуль для создания визуальной составляющей к аддонам
TODO: Завершить модуть симуляции
TODO: Перевод на TypeScript
TODO: Redux - преобразовать с Redux Toolkit
*/

import React, {Suspense, useEffect} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";

import store from "./redux/store"

import './style/main.scss'
import Preloader from "./domains/general/assets/preloader";

const AllodsOnline = React.lazy(() => import("./domains/allods_online/bodyContainer"))
const General = React.lazy(() => import("./domains/general/bodyContainer"))
const SpecialWelcome = React.lazy(() => import("./domains/general/pages-special/welcome/index"))

const Rerender = (state) => {
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <Switch>
                        {/*{ !window.localStorage.getItem('page/welcome/isWatched') && <Route strict path={["/"]} render={() => <Suspense fallback={<Preloader/>}><SpecialWelcome/></Suspense> }/> }*/}
                        <Route exact path={["/welcome"]} render={() => <Suspense fallback={<Preloader/>}><SpecialWelcome/></Suspense>}/>
                        <Route strict path={["/ao"]} render={() => <Suspense fallback={<Preloader/>}><AllodsOnline/></Suspense>}/>
                        <Route strict path={["/"]} render={() => <Suspense fallback={<Preloader/>}><General/></Suspense>}/>
                    </Switch>
                </Provider>
            </BrowserRouter>
        </React.StrictMode>,
        // document.body
        document.getElementById('root')
    );
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        reportWebVitals();
};

Rerender(store.getState());
store.subscribe(() => {
    let state = store.getState()
    // window.state = state
    Rerender(state)
})