const getUri = ({data, type = "main"}) => {
    const transform = (data) => {
        return `${data.protocol ? data.protocol : 'http'}://${data.host}${data.port ? `:${data.port}` : ''}`
    }
    switch (type) {
        case "main": {
            return transform(data[0])
        }
        case "all" : {
            return data.map(d => transform(d))
        }
        default: {
            return data.map(d => transform(d))
        }
    }
}

const config = {
    app: {
        config: [
            {
                protocol: 'http',
                host: 'xn----otbqdjelm.xn--p1ai'
            },
            {
                protocol: 'http',
                host: 'ovz5.j715957.m7o9p.vps.myjino.ru',
            },
            {
                protocol: 'http',
                host: 'localhost',
                port: 3000,
            },
            {
                protocol: 'https',
                host: 'xn----otbqdjelm.xn--p1ai'
            },
        ],
        consoleErros: false,
        client: 'web:site',
        key: 'erecen5eqtC1CGtwWdaB',
        get (param) { return this.config[0].hasOwnProperty(param) ? this.config[0][param] : null },
        getUri (type) {
            return window.location.origin
            // return getUri({data: this.config, type})
        }
    },
    desktop: {
        config: [
            {
                protocol: 'http',
                host: 'localhost',
                port: 3000,
            },
        ],
        get (param) { return this.config[0][param] },
        getUri (type) {
            return window.location.origin
            // return getUri({data: this.config, type})
        }
    },
    api: {
        config: [
            {
                protocol: 'https',
                host: 'xn----otbqdjelm.xn--p1ai',
            },
            {
                protocol: 'http',
                host: 'ovz5.j715957.m7o9p.vps.myjino.ru',
                port: 49202,
            },
            {
                protocol: 'http',
                host: 'xn----otbqdjelm.xn--p1ai',
                port: 5000,
            },
            {
                protocol: 'http',
                host: 'localhost',
                port: 5000,
            },
        ],
        get (param) { return this.config[0][param] },
        getUri (type) { return getUri({data: this.config, type}) }
    },
    server: {
        config: [
            {
                protocol: 'https',
                host: 'xn----otbqdjelm.xn--p1ai',
            },
            {
                protocol: 'http',
                host: 'ovz5.j715957.m7o9p.vps.myjino.ru',
                port: 49202,
            },
            {
                protocol: 'http',
                host: 'xn----otbqdjelm.xn--p1ai',
                port: 5000,
            },
            {
                protocol: 'http',
                host: 'localhost',
                port: 5000,
            },
        ],
        get (param) { return this.config[0][param] },
        getUri (type) { return getUri({data: this.config, type}) }
    },
    socket: {
        port: 4000,
    },
    vk: {
        appId: 6197496,
        appKey: 'erecen5eqtC1CGtwWdaB',
        v: '5.52'
    }
}
export {
    config as default
}